import React from 'react';

import PageWrap from 'components/PageWrap';
import { Section } from 'components/Section';
import { PagePicTitle } from 'components/PagePicTitle';

import { FeaturePoint } from 'components/FeaturePoint';
import CallsToAction from 'components/CallsToAction';
import { Separator } from 'components/Separator';
import { SEO } from 'components/Helmets';

import LocationsIcon from 'media/shared/modIcons/Locations.inline.svg';

import makeBookingImg from 'media/Screenshots/Booking/MakingABooking.png';
import calendarImg from 'media/Screenshots/Booking/Calendar.png';
import teamManagerImg from 'media/Screenshots/Booking/TeamManager.png';
import hrSetupImg from 'media/Screenshots/Booking/HRSetup.png';

export default function ModBooking({data}){
    const CTATypes = ['SignUp', 'Demo'];
    const pagePath = "/modules/booking";

    return (
        <PageWrap noSEO={true} path={pagePath}>
            <SEO
                title="The Booking Module - Mashoom"
                description="Simple HR, time booking and location management."
                previewImgKey="locations"
                path={pagePath}
            />
            <PagePicTitle
                imageCmp={LocationsIcon}
                imageAlt="Booking module icon"
            >
                <h1>The Booking Module</h1>
                <p>Knowing where you team is today and in the future is important from many perspectives. Making bookings easy means that people do it consistently and accurately.</p>
            </PagePicTitle>
            <FeaturePoint
                title="Making bookings as simple as possible"
                image={makeBookingImg}
                pageInd="1"
            >
                <p>At the core of any booking system must be a straightforward interface for everyone to add their bookings. Many iterations with lots of feedback mean we are fairly confident no-one should have issues with ours.</p>
                <p className="paraSpaceTop">It's the same process whether you are booking holiday, a meeting room, a training day or a hot desk; it's all in one place. Each location or activity is setup with a booking interval, so whilst for instance a meeting room would ask you to select a time of day to 15 minute accuracy, a training day would only require a number of days from a date.</p>
                <p className="paraSpaceTop">A clear and concise confirmation box shows the amount of a quota left and who will need to authorise a booking if required.</p>
                <p className="paraSpaceTop">Alternatively, scheduled check-in emails can be setup that allow a single click on an email to allow people to say where they are.</p>
            </FeaturePoint>
            <Separator />
            <FeaturePoint
                title="Quickly find out where your team are or who is using a space"
                image={calendarImg}
                pageInd="2"
            >
                <p>No more sharing calendars or complex interfaces just to see if your college is going to be in the office on given day, it's at your fingertips.</p>
                <p className="paraSpaceTop">"Location admins" allow selected people to view the logs for a given location, this would be appropriate for a receptionist or fire marshal.</p>
                <p className="paraSpaceTop">Locations can be setup in a hierarchical structure, for instance hot desks could be setup within a certain office. This gives Mashoom a much more complete picture of your spaces, which in turn allows us to provide much more intelligent interfaces, feedback and monitoring.</p>
            </FeaturePoint>
            <Separator />
            <FeaturePoint
                title="Super powered team management"
                image={teamManagerImg}
                pageInd="3"
            >
                <p>It shouldn't take a degree in computing to be able to see what your team are up to, nor should managing holiday bookings be more than a few clicks. The team manager page is your super weapon to stop the time wasting and get you back to being a manager.</p>
                <p className="paraSpaceTop">If check-in emails are setup, managers will also be see if any of their team haven't checked in so they can follow up and make sure they are OK. This can fulfil a basic duty of care requirement when people are working flexibly.</p>
                <p className="paraSpaceTop">Let's be practical, sometimes a manager needs to amend their team's calendars for all sorts of reasons, we've made this as simple as possible.</p>
            </FeaturePoint>
            <Separator />
            <FeaturePoint
                title="Top down tools tailored for human resource administrators"
                image={hrSetupImg}
                pageInd="4"
            >
                <p>Whether the HR function is done by a team, person, or it's just another role being played by the CEO, having a centralized place to manage how many holiday days people have and who people's managers are is vital.</p>
                <p className="paraSpaceTop">Public holidays for most regions are able to be setup on an organizational or individual basis. Custom holidays can also be setup, for instance to handle part time workers or if your company has any specific policies.</p>
                <p className="paraSpaceTop">Quotas are also setup from this central management page. Quotas are attached to any activity or location, so for instance setting up the amount of holiday days people are allowed per year or the number of CPD days per month.</p>
            </FeaturePoint>
            <Section id="FeatureCTA">
                <div id="FeatureCTACont">
                    <h2>Like what your hear?</h2>
                    <CallsToAction types={CTATypes}></CallsToAction>
                </div>
            </Section>
        </PageWrap>
    );
}